import React from 'react';
import { Breadcrumb, Container, Row, Col } from 'react-bootstrap';
import './Reviews.scss';
import Header from '../../components/Header/Header';
import Banner from '../../components/BannerComp/Banner';
import Faq from '../../components/FaqList/Faq';
import CurrentOpenings from '../../components/CurrentOpenings/CurrentOpenings';
import Footer from '../../components/Footer/Footer';
import reviewer1Mob from '../../images/reviews/review-1-376.jpg'
import reviewer1Tab from '../../images/reviews/review-1-768.jpg'
import reviewer2Tab from '../../images/reviews/review-2-768.jpg'
import reviewer1Desk from '../../images/reviews/review-1-1400.jpg'
import reviewer2Desk from '../../images/reviews/review-2-1400.jpg'
import reviewer3Desk from '../../images/reviews/review-3-1400.jpg'
import reviewer1DeskLg from '../../images/reviews/review-1-1800.jpg'
import reviewer2DeskLg from '../../images/reviews/review-2-1800.jpg'
import reviewer3DeskLg from '../../images/reviews/review-3-1800.jpg'

const bannerData = {
	heading: 'Customer Reviews',
	details: 'Etiam cursus augue ac maximus maximus. Phasellus lobortis ante at erat fringilla, viverra pulvinar augue dictum quisque molestie lacinia lectus.',
	rating: 4.9,
	maxRating: 5,
	rateCount: 28,
	reviewerData: [
		{
			reviewerImgMob: reviewer1Mob,
			reviewerImgTab: reviewer1Tab,
			reviewerImgDesk: reviewer1Desk,
			reviewerImgDeskLg: reviewer1DeskLg,
			reviewerName: 'Alex Patterson',
			reviewerType: 'buyer'
		},
		{
			reviewerImgMob: reviewer1Mob,
			reviewerImgTab: reviewer2Tab,
			reviewerImgDesk: reviewer2Desk,
			reviewerImgDeskLg: reviewer2DeskLg,
			reviewerName: 'Emily Adams',
			reviewerType: 'seller'
		},
		{
			reviewerImgMob: reviewer1Mob,
			reviewerImgTab: reviewer2Tab,
			reviewerImgDesk: reviewer3Desk,
			reviewerImgDeskLg: reviewer3DeskLg,
			reviewerName: 'Tim Jenkinson',
			reviewerType: 'landlord'
		},
	]
}

const Reviews = () => {
	return (
		<div className='reviews-page'>
			<Header/>
			<div className="breadcrumbs-wrap">
				<Container>
					<Row>
						<Col>
							<Breadcrumb>
								<Breadcrumb.Item href="#">Home</Breadcrumb.Item>
								<Breadcrumb.Item 
								href="#">
									About
								</Breadcrumb.Item>
								<Breadcrumb.Item active>Customer Reviews</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
					</Row>
				</Container>
			</div>
			<Banner bannerData={bannerData} />
			<Faq />
			<CurrentOpenings />
			<Footer />
		</div>
	)
}

export default Reviews